<template>
    <div class="p-fluid mb-3">       
        <div class="grid">
            <div class="lg:col-5 md:col-12 sm:col-12 align-items-center justify-content-center">
                <h5>Guide</h5>
                <ol>
                    <li>
                        <p class="line-height-3 m-0">Please download the template first <a target="_blank" href="https://docs.google.com/spreadsheets/d/10Au8FPReFj8_RUdUa9sPp2Be7_Gttoz1/edit#gid=652544760"><u><i>HERE</i></u></a> </p>
                    </li>
                    <li>
                        <p class="line-height-3 m-0">For the list of data outlet types, please refer to the
                            <router-link to="/outlet-type">
                                <i><span> "Master - Outlet Type" </span></i>
                            </router-link>
                            page.
                        </p>
                    </li>
                    <li>
                        <p class="line-height-3 m-0">Ensure that the uploaded file is in Excel or CSV format.</p>
                    </li>
                    <li>
                        <p class="line-height-3 m-0">Make sure the file size does not exceed 2MB.</p>
                    </li>
                    <li>
                        <p class="line-height-3 m-0">Ensure that the columns are filled in correctly.</p>
                    </li>
                </ol>
                <div class="line-height-3 m-0" style="color:red;">Note: Please follow the step-by-step instructions carefully to ensure a successful data upload.</div>
                <!-- <h5>Download Template</h5>
                <div class="p-fluid">
                    <div class="formgrid grid">
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div class="field mt-2 ml-2">
                                <Button :loading="loadingDownloadTemplate" label="Download" icon="pi pi-download" class="p-button-success my-1" @click="downloadTemplate('xlsx')" />
                                <Button :loading="loadingDownloadTemplate" label="Download" icon="pi pi-download" class="p-button-success my-1" @click="downloadTemplateStatis()" />
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            
            <div class="lg:col-1">
                <Divider layout="vertical">
                    <!-- <b>OR</b> -->
                </Divider>
            </div>

            <div class="sm:col-12 md:col-12 lg:col-6">
                <BlockUI :blocked="blockPanel">
                    <form>
                        <div class="p-fluid">
                            <div class="formgrid grid">
                                <div class="field col-12 md:col-12 lg:col-6">
                                    <!-- FORM -->
                                </div>
                            </div>
                        </div>
                        <FileUpload ref="fileUpload" name="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" chooseLabel="Choose File" uploadLabel="Upload" cancelLabel="Cancel" :customUpload="true" @uploader="onUpload" :fileLimit="1" :maxFileSize="2000000">
                        <template #empty>
                            <p>Drag and drop the file here to upload.</p>
                            <ProgressBar mode="indeterminate" style="height: .5em" v-if="blockPanel"/>
                        </template>
                        </FileUpload>
                    </form>
                </BlockUI>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ['submit'], 
    data() {
        return {
            // loading
            loadingDownloadTemplate: false,

            // upload
            blockPanel: false,
            file: null,
        }
    },
    mounted() {
        this.$store.commit('setErrors', {});
    },
    computed: {
        ...mapGetters(['errors']),
    },
    methods: {
        // DOWNLOADTEMPLATE
        // downloadTemplate(ext){
        //     this.loadingDownloadTemplate= true;

        //     this.axios({
        //         method: 'GET',
        //         url: process.env.VUE_APP_ROOT_API + 'web/master-outlet/template-upload',
        //         responseType: 'blob',
        //         params: {
        //             "ext" : ext,
        //         }
        //     })
        //     .then(response => {
        //         let fileUrl = window.URL.createObjectURL(response.data);
        //         let fileLink = document.createElement('a');

        //         fileLink.href = fileUrl;

        //         fileLink.setAttribute('download', 'Import Master Outlet Template.' + ext);
                
        //         document.body.appendChild(fileLink)

        //         fileLink.click();

        //         this.loadingDownloadTemplate= false;

        //     })
        //     .catch((err) => {
        //         console.log(err);

        //         this.loadingDownloadTemplate= false;
        //     });
        // },
        // downloadTemplateStatis(){
        //     this.loadingDownloadTemplate= true;

        //     const url = 'template-upload/template_master_outlet.xlsx';
        //     window.location.href = url;

        //     this.loadingDownloadTemplate= false;
        // },
        //UPLOAD
        onUpload(event) {

            this.$store.commit("setErrors", {});
            this.blockPanel = true;
            this.file = event.files[0];

            let data = new FormData();
            data.append('file', this.file);

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/outlet/import',
                data: data,
                onUploadProgress: function (e) {
                     this.$refs.fileUpload.progress = parseInt(Math.round((e.loaded * 100) / e.total));
                }.bind(this)
            })
			.then(res => {
                console.log(res);
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000});
                this.$store.commit("setErrors", {});
                this.$refs.fileUpload.uploadedFileCount = 0;
                this.$refs.fileUpload.progress = null;
                this.blockPanel = false;

                this.clearForms();

                this.$emit('submit');
			})
            .catch((err) => {
                console.log(err);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
                this.$refs.fileUpload.uploadedFileCount = 0;
                this.$refs.fileUpload.progress = null;
                // this.$refs.message.visible = true;
                this.blockPanel = false;
            });
        },
        clearForms() {
            this.file = null;
        },
    }
}
</script>