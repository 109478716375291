<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Outlet Code</label>
                <InputText v-model="forms.outlet_code" required="true" autofocus :class="{ 'p-invalid': errorAdd.outlet_code }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.outlet_code" >{{ errorAdd.outlet_code[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Outlet Name</label>
                <InputText v-model="forms.outlet_name" required="true" autofocus :class="{ 'p-invalid': errorAdd.outlet_name }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.outlet_name" >{{ errorAdd.outlet_name[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Outlet Contact Person</label>
                <InputText v-model="forms.outlet_owner" required="true" autofocus :class="{ 'p-invalid': errorAdd.outlet_owner }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.outlet_owner" >{{ errorAdd.outlet_owner[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Outlet Contact Number</label>
                <InputText v-model="forms.outlet_phone" required="true" autofocus :class="{ 'p-invalid': errorAdd.outlet_phone }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.outlet_phone" >{{ errorAdd.outlet_phone[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Outlet Address</label>
                <InputText v-model="forms.outlet_address" required="true" autofocus :class="{ 'p-invalid': errorAdd.outlet_address }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.outlet_address" >{{ errorAdd.outlet_address[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Outlet Latitude</label>
                <InputText v-model="forms.lat" required="true" autofocus :class="{ 'p-invalid': errorAdd.lat }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.lat" >{{ errorAdd.lat[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Outlet Longitude</label>
                <InputText v-model="forms.lng" required="true" autofocus :class="{ 'p-invalid': errorAdd.lng }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.lng" >{{ errorAdd.lng[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Outlet Type</label>
                    <Dropdown dataKey="otype_id" v-model="forms.otype_id" :loading="loadingDropdownOutletType"
                        :options="dataDropdownOutletType" :class="{ 'p-invalid': errorAdd.otype_id }"
                        optionLabel="otype_name" optionValue="otype_id" placeholder="Select Outlet Type"
                        :filter="true" :showClear="true" @filter="searchDropdownOutletType($event, 'add')"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.otype_id">{{
                        errorAdd.otype_id[0]
                }}</small>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Outlet City</label>
                <!-- <div class="field-checkbox mt-2">
                    <Checkbox id="isAllOutlet" v-model="checked" :binary="true" :disabled="forms.area_id == null" @change="this.forms.city_id = [];"/>
                    <label for="isAllOutlet">Centang jika pilih semua Outlet</label>
                </div> -->
                <Listbox ref="doutletcity" v-model="forms.city_id" :options="dataDropdownOutletCity" :class="{ 'p-invalid': errorAdd.city_id }" :multiple="true" :filter="true" optionLabel="label"  listStyle="max-height:250px" style="width:15rem" @filter="searchDropdownOutletCity($event, 'add')">
                    <template #option="slotProps">
                        <div>{{slotProps.option.label}}</div>
                    </template>
                </Listbox>
                <small class="p-invalid" style="color: red" v-if="errorAdd.city_id">{{
                    errorAdd.city_id[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-9" v-if="convertMultiSelectOutletCity(forms.city_id).length != 0">
                <div class="card" style="height: calc(60vh - 143px)">
                    <DataTable :value="forms.city_id" :scrollable="true" scrollHeight="flex">
                        <Column field="label" header="Outlet City">
                            <template #body="slotProps">
                                {{ slotProps.data.label }}
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Save" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownOutletCity: false,
            loadingDropdownOutletType: false,

            // dataDropdown
            dataDropdownOutletCity: null,
            dataDropdownOutletType: null,

            // addNew
            forms: {
                outlet_code: null,
                outlet_name: null,
                outlet_owner: null,
                outlet_phone: null,
                outlet_address: null,
                otype_id: null,
                lat: null,
                lng: null,
                city_id: [],
            },
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownOutletCity('');
        this.searchDropdownOutletType('');
    },
    computed:{
        ...mapGetters(['errorAdd']),
		...mapGetters("auth", ["user"]),
    },
    methods: {
        convertMultiSelectOutletCity(data) {
            if(data){
                return data.map((item) => {
                    return item.city_id;
                });
            }else{
                return [];
            }
        },
        // DROPDOWN
        searchDropdownOutletCity(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.doutletcity.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownOutletCity = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-city3',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if (purpose == "add") {
                        this.dataDropdownOutletCity = res.data.data;
                        this.loadingDropdownOutletCity = false;
                    } else if (purpose == null) {
                        this.dataDropdownOutletCity = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            }, 250);
        },
        searchDropdownOutletType(event, purpose, valueEdit){
            setTimeout(() => {
                if(valueEdit){
                    this.$refs.dotype.filterValue = valueEdit;
                }

                if(purpose == "add"){
                    this.loadingDropdownOutletType = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/outlet-type',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "add"){
                        this.dataDropdownOutletType = res.data.data;
                        this.loadingDropdownOutletType = false;
                    }else if(purpose == null){
                        this.dataDropdownOutletType = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/outlet/create',
                data: {
                    "outlet_code": this.forms.outlet_code,
                    "outlet_name": this.forms.outlet_name,
                    "outlet_owner": this.forms.outlet_owner,
                    "outlet_phone": this.forms.outlet_phone,
                    "outlet_address": this.forms.outlet_address,
                    "otype_id": this.forms.otype_id,
                    "lat": this.forms.lat,
                    "lng": this.forms.lng,
                    "city_id": this.convertMultiSelectOutletCity(this.forms.city_id),
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
        },
        clearForms() {
            this.forms.outlet_code = null;
            this.forms.outlet_name = null;
            this.forms.outlet_owner = null;
            this.forms.outlet_phone = null;
            this.forms.outlet_address = null;
            this.forms.lat = null;
            this.forms.lng = null;
            this.forms.city_id = [];
        },
    }
}
</script>